import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components'

import CustomBuildingForm from "../../components/CustomBuildingForm"
import { Button } from "../../components/FormStyle"

const Wrapp = styled.div`
	position: relative;
  background-image: linear-gradient(0deg, rgba(112, 112, 112, 1) 0.1em, transparent 6.04px);
  background-size: 1em 1em;
	& h2 {
		color: white;
		text-align: center;
		margin-bottom: 77px;
	}
	@media(max-width: 768px){
		& h2 {
			margin-bottom: 50px;
		}
		& h5 {
			margin-top: 40px;
		}
	}
	@media(max-width: 480px){
		& h5 {
			margin-top: 20px;
		}
	}
`
const Visualize = styled.div`
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 50%;
	background: rgba(6, 44, 68, 0.98);
	height: 850px;
	padding-top: 78px;
	padding-right: calc(50vw - 800px);
	padding-left: 5%;
	& .gatsby-image-wrapper {
		width: 100%;
		max-width: 700px;
		margin: auto;
	}
	@media(max-width: 1700px){
		padding: 78px 5% 128px;
	}
	@media(max-width: 1200px){
		width: 100%;
		display: block;
		height: auto;
		& .gatsby-image-wrapper {
			max-width: 700px;
			width: 100%;
			margin: auto;
		}
	}
`
const DesignButtonWrap = styled.div`
	position: absolute;
	z-index: 53;
	bottom: 100px;
	left: 0;
	text-align: center;
	width: 100%;
	max-width: calc(800px + 10%);
	@media(max-width: 1200px){
		bottom: 50px;
		text-align: center;
		max-width: 100%;
	}
`

const ThreeDHouse = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "3d-house.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} alt='3D Design Tool'/>
}


const VisualSection = ({location}) =>{
	return(
		<Wrapp>
			<CustomBuildingForm location={location} />
			<Visualize>
				<h2>You can use 3D Design Tool to Customize & Visualize your Building.</h2>
				<ThreeDHouse />
				<DesignButtonWrap>
					<a href="https://probuiltsteel.sensei3d.com/" target="_blank" rel="noreferrer">
						<Button width="350px" mWidth="250px"><div>3D Estimator</div></Button>
					</a>
				</DesignButtonWrap>
			</Visualize>
		</Wrapp>
	)
}

export default VisualSection