import React, { Component } from 'react'
import styled from "styled-components"
import Img from "gatsby-image"
import Slider from "react-slick";
import LazyLoad from 'react-lazyload';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./styles.css"

const Wrapper = styled.div`
  position: relative;
  & img {
    object-fit: cover;
  }
  .slick-slider{
    .slider-track {
      -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
      transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
    }
    .slick-slide{
      & img {
        width: 100%;
        -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        -webkit-transform: scale(1.3);
                transform: scale(1.3);
      }
      &.slick-active{
        & img {
          -webkit-transform: scale(1);
          transform: scale(1);
        -webkit-animation: cssAnimation 8s 1 ease-in-out forwards;
        animation: cssAnimation 8s 1 ease-in-out forwards;

          
        }
      }
    }
  }
  
`

export default class ImageCarousel extends Component {
  render() {
    const { imageData } = this.props
   
    const settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: true,
      autoplay: true,
      autoplaySpeed: 7000,
      arrows: false,
      dots: false,
      fade: true,
      speed: 500,
      infinite: true,
      cssEase: 'ease-in-out',
      touchThreshold: 10
    };
    return (
      <Wrapper className="hero-carousel">
        <Slider {...settings}>
          {
            imageData.map((item, i)=> {
              return(
                <Img fluid={item.fluid} alt={item.title} />
              )
            })
          }
        </Slider>
      </Wrapper>
    );
  }
}