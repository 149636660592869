import React, { useRef } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { Link } from "gatsby"

import { Section, SectionTitle, SectionButtonWrapper } from "../Section"
import SectionButton from "../Button/SectionButton"
import TopComma from "../Icons/TopComma"
const Description = styled.div`
	text-align:center;
	
`
const SliderContent = styled.div`
  position: relative;
  margin-top: 30px;
  & ul {
    position: relative !important;
    transform: none !important;
    width: 100% !important;
    top: 20px !important;
    right: 0 !important;

    & li {
      display: inline-block;
    }
  }
  & .slick-dots li.slick-active button:before {
    & li {
      & button {
        & :before {
          font-size: 12px;
          color: #f66226;
        }
      }
      & .slick-active {
        & button {
          &:before {
            color: #ed602b;
          }
        }
      }
    }
  }
`
const Card = styled.div`
  padding: 10px;
  min-height: 350px;
`

const CardText = styled.div`
  background: #f4fbff;
  padding: 60px 60px 43px 60px;
  height: 300px;
  & p {
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  & h5 {
    font-size: 16px;
    font-weight: bold;
    color: #010920;
    margin-top: 17px;
    margin-bottom: 3px;
  }

  & div {
    font-size: 14px;
    color: #676b79;
  }
  @media (max-width: 600px) {
    padding: 50px 20px 43px 20px;
  }
`

const TopIcon = styled.div`
  position: relative;
  margin-top: -30px;
  margin-bottom: 25px;
  z-index: 50;
`

const commonSt = `
  display: flex;
  align-items: center;
`

const LeftShadow = styled.div`
  position: absolute;
  width: 25%;
  height: 90%;
  left: 0;
  top: 10%;
  z-index: 10;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.1)
  );
  @media (max-width: 500px) {
    width: 0;
  }

  ${commonSt}
  padding-left: 20px;
`
const RightShadow = styled.div`
  position: absolute;
  width: 25%;
  height: 90%;
  right: 0;
  top: 10%;
  z-index: 10;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.1)
  );
  @media (max-width: 500px) {
    width: 0;
  }
  ${commonSt}
  padding-right: 20px;
  justify-content: flex-end;
`

const CommonPane = `
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 11;
  display: flex;
  align-items: center;
`

const PrevPane = styled.div`
  ${CommonPane}
  left: 0;
`

const NextPane = styled.div`
  ${CommonPane}
  right: 0;
`

export const Controller = styled.div`
  margin: 20px 0;
`

export const SliderControlButtonWrap = styled.div`
  margin: auto;
  width: 120px;
  display: flex;
  justify-content: space-between;
`

export const ControlBtn = styled.button`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #0059B2;
  transition: transform 0.3s ease;

  &:hover {
    background: #062c44;
    transform: scale(1.1);
  }
`

const TestimonialCarousel = ({ data, sectionData }) => {
  const sliderRef = useRef();

  const settings = {
    className: "center",
    centerMode: true,
    dots: false,
    autoPlay: true,
    infinite: true,
    centerPadding: "25%",
    slidesToShow: 1,
    speed: 500,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "10%",
        },
      },
    ],
  }

  const renderSliderContent = () => {
    return data.edges.map((item, i) => {
      return (
        <Card key={i}>
          <CardText>
            <TopIcon>
              <TopComma />
            </TopIcon>
            <p>{item.node.description?.description}</p>
            <h5>{item.node.customerName}</h5>
          </CardText>
        </Card>
      )
    })
  }

  return (
    <Section>
      <div className="container">
        <SectionTitle>{sectionData.title}</SectionTitle>
        <Description>
          <p
            dangerouslySetInnerHTML={{
              __html: sectionData.description.childMarkdownRemark.html,
            }}
          />
        </Description>
        <SliderContent>
          <LeftShadow />
          <PrevPane>
            <ControlBtn onClick={() => sliderRef.current.slickPrev()}>
              <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.58363 14.7955L9.23136 13.1619L4.90607 8.83665H15.041V6.43608H4.90607L9.23136 2.1179L7.58363 0.477273L0.424538 7.63636L7.58363 14.7955Z" fill="white" />
              </svg>
            </ControlBtn>
          </PrevPane>
          <Slider
            {...settings}
            ref={sliderRef}
          >{renderSliderContent()}</Slider>
          <NextPane>
            <ControlBtn onClick={() => sliderRef.current.slickNext()}>
              <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.41637 14.7955L6.76864 13.1619L11.0939 8.83665H0.958984V6.43608H11.0939L6.76864 2.1179L8.41637 0.477273L15.5755 7.63636L8.41637 14.7955Z" fill="white" />
              </svg>
            </ControlBtn>
          </NextPane>
          <RightShadow />
        </SliderContent>
        <SectionButtonWrapper mt="30px">
          <Link to="/reviews/">
            <SectionButton text="Explore All Reviews" />
          </Link>
        </SectionButtonWrapper>
      </div>
    </Section>
  )
}

export default TestimonialCarousel;
