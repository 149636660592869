import React from "react"
import { graphql } from "gatsby"
import LazyLaod from "react-lazyload"

import Layout from "../components/layout"
import SEO from "../components/seo"


import HeroSection from "../sections/Home/HeroCover"
import IntroSection from "../sections/Home/IntroSection"
import FeaturesSection from "../sections/Home/FeaturesSection"
import OverviewSection from "../sections/Home/OverviewSection"
import VisualSection from "../sections/Home/VisualSection"
import MapSection from "../sections/Home/MapSection"
import CardSection from "../sections/Home/CardSection"
import SmallDepositSection from "../sections/Home/SmallDepositSection"
import TestimonialCarousel from "../components/TestimonialCarousel"
import ArticleCarousel from "../components/ArticleCarousel"

const IndexPage = ({ data, location }) => {
  const homePageData = data.contentfulHome
  const HeroData = homePageData.heroSection
  const IntroData = homePageData.introSection
  const IntroImageData = data.accordian
  const FeaturesData = homePageData.featuresSection
  const OverviewData = homePageData.overviewSection
  const CardData = homePageData.cardSection
  const DepositData = homePageData.smallDepositSection
  const MapData = homePageData.mapSection
  const TestimonialSectionData = homePageData.reviewSection
  const TestimonialData = data.allContentfulTestimonial
  const AticleSectionData = homePageData.blogSection
  const ArticleData = data.allContentfulArticle
  const AllProducts = data.allContentfulProduct

  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "ProBuilt Steel Buildings",
    "alternateName": "ProBuilt",
    "url": "https://www.probuiltsteel.com/",
    "logo": "https://www.probuiltsteel.com/static/logo-9b82a20b2d5c6f5abcf45d07f8ac5bc9.svg",
    "sameAs": [
      "https://www.facebook.com/ProBuiltSteelBuildings/",
      "https://www.youtube.com/channel/UCgX_B_Nfel_Z9Tbo1JLSCCA",
      "https://maps.app.goo.gl/YfegezsAoHVxDSxR7",
      "https://www.instagram.com/probuiltsteelbuildings/"
    ]
  }
  return (
    <Layout location={location}>
      <SEO
        title={homePageData.metaTitle}
        description={homePageData.metaDescription}
        schemaMarkup={schema}
        meta={[{
          name: `google-site-verification`,
          content: "Bp-EGTtXSWETzf5Y5t4xVcwO-ofsQi0RjSDd2sjVvz4",
        }]}
      />
      <HeroSection data={HeroData} location={location} />
      <IntroSection data={IntroData} imageData={IntroImageData.edges} />
      <FeaturesSection data={FeaturesData} />
      <LazyLaod>
        <OverviewSection data={OverviewData} />
      </LazyLaod>
      <LazyLaod>
        <VisualSection location={location} />
      </LazyLaod>
      <LazyLaod>
        <CardSection data={CardData} productData={AllProducts} />
      </LazyLaod>
      <LazyLaod>
        <SmallDepositSection data={DepositData} />
      </LazyLaod>
      <LazyLaod>
        <MapSection data={MapData} />
      </LazyLaod>
      <LazyLaod>
        <TestimonialCarousel sectionData={TestimonialSectionData} data={TestimonialData} />
      </LazyLaod>
      <LazyLaod>
        <ArticleCarousel sectionData={AticleSectionData} data={ArticleData} />
      </LazyLaod>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery {
    contentfulHome {
      title
      metaTitle
      metaDescription
      heroSection {
        title
        description {
          description
        }
        images {
          fluid(quality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcWebp
            srcSetWebp
            srcSet
          }
        }
      }
      introSection {
        title
        description {
          description
        }
        features {
          name
          image
          link
          description
          amount
        }
      }
      featuresSection {
        title
        description {
          description
        }
        features {
          image
          title
          description
        }
      }
      overviewSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
      }
      cardSection {
        title
        description {
          description
        }
      }
      smallDepositSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        features {
          title
          description
        }
      }
      mapSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
      }
      reviewSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
      }
      blogSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulArticle(sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          title
          publishDate(formatString: "DD, MMMM YYYY")
          description {
            description
          }
          tumbnail {
            fluid(maxHeight: 700) {
              base64
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
              aspectRatio
            }
          }
          category {
            name
          }
          createdAt(fromNow: true)
        }
      }
    }
    allContentfulTestimonial {
      edges {
        node {
          customerName
          description {
            description
          }
        }
      }
    }
    accordian: allContentfulAsset(filter: {title: {regex: "/acc-/"}}) {
      edges {
        node {
          title
          fluid(maxWidth: 1600, quality: 100) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
    }
    allContentfulProduct(sort: {fields: skuNumber}) {
      edges {
        node {
          id
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          productServices {
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          numberOfSale
          canBeUsedAs {
            content
          }
        }
      }
    }
  }
`