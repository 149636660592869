import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import LazyLoad from "react-lazyload"
import { StaticImage } from "gatsby-plugin-image"

import { SectionTitle } from "../../components/Section"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import MVVideoModal from "../../components/MVVideoModal"

const Wrap = styled.div`
  background-image: linear-gradient(
    90deg,
    rgba(112, 122, 122, 1) 0.1em,
    transparent 0.5px
  );
  background-size: 3em 3em;
  @media (max-width: 768px) {
    background-size: 1.5em 1.5em;
  }
`
const Section = styled.div`
  background: rgba(255, 255, 255, 0.98);
  padding: 150px 0;
  @media (max-width: 768px) {
    padding: 75px;
  }

  @media (max-width: 768px) {
    padding: 49px 0 50px;
    background: rgba(255, 255, 255, 0.9);
  }
`

const ContentGrid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 66px;
  grid-row-gap: 35px;
  & p {
    text-align: left;
    margin: 12px 0;
  }

  & a {
    color: #ed602b;
    font-size: 16px;
    font-weight: 800;
    text-decoration: none;
    & svg {
      width: 30px;
      margin-left: 5px;
      transition: ease 0.3s;
    }
    &:hover {
      cursor: pointer;
      & svg {
        margin-left: 10px;
        transition: ease 0.3s;
      }
    }
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
    max-width: 600px;
    width: 100%;
    margin: auto;
    & h2,
    p,
    a,
    div {
      text-align: center !important;
    }
  }
`
const Description = styled.div`
  & p {
    font-size: 16px;
    line-height: 26px;
    color: #010920;
    margin-bottom: 26px;
  }
`
const VideoWrap = styled.div`
  position: relative;
  width: 100%;
  & video {
    width: 100%;
  }
  .play-button {
    position: absolute;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
    translate: trasform(-50%, -50%);
    width: 60px;
    height: 60px;

    &:hover{
      cursor: pointer;
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
  }
`
class OverviewSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVideoModalOpen: false,
    }
    this.openVideoModal = this.openVideoModal.bind(this)
    this.closeVideoModal = this.closeVideoModal.bind(this)
  }
  
  openVideoModal() {
    this.setState({
      isVideoModalOpen: true,
    })
  }
  closeVideoModal() {
    this.setState({ isVideoModalOpen: false })
  }
  render() {
    const { data } = this.props
    return (
      <Wrap>
        <LazyLoad>
          <MVVideoModal
            isOpen={this.state.isVideoModalOpen}
            onClose={this.closeVideoModal}
          />
        </LazyLoad>
        <Section>
          <div className="container">
            <ContentGrid>
              <div>
                <SectionTitle textAlign="left">{data.title}</SectionTitle>
                <Description
                  dangerouslySetInnerHTML={{
                    __html: data.description.childMarkdownRemark.html,
                  }}
                />
                <Link to="/about/">
                  Know More <ArrowIcon fill="#ED602B" />
                </Link>
              </div>
              <LazyLoad>
                <VideoWrap>
                  <StaticImage src="../../images/video-poster.png" alt="video poster" />
                  <div className="play-button" onClick={this.openVideoModal}>
                    <StaticImage src="../../images/play-button.png" alt="play button" />
                  </div>
                </VideoWrap>
              </LazyLoad>
            </ContentGrid>
          </div>
        </Section>
      </Wrap>
    )
  }
}

export default OverviewSection
