import React from 'react'
import FirstStepBar from "../VerticalStepBar/FirstStepBar"
import {Types, Label, CustomValidationMessage, RequiredTriangle} from "../FormStyle"


class Step1 extends React.Component {
  render() {
    const {  buildingType, stateRequire, state, stateInputPattern } = this.props

    if (this.props.currentStep !== 1) { // Prop: The current step
      return null
    }
    // The markup for the Step 1 UI
    return(
    <div className="form-group">
      <h5>Choose Your Installation State</h5>
      <div>
        <input name="state" id="state" placeholder="Your State Zip Code" style={{width: 280}} onChange={this.props.handleChange} pattern="^[0-9]{5}$" aria-label="state zip code" value={state} require />
        <CustomValidationMessage display={stateInputPattern ==="" ?"none": "block"} width="fit-content"><RequiredTriangle /><span>{stateInputPattern}</span></CustomValidationMessage>
        <CustomValidationMessage display={stateRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
      </div>
      <h5 style={{marginTop: 63}}>Choose Your Building Type</h5>
      <Types>
        <Label bg={buildingType==="Carports" ? "#EA5F2C" : "none"} borderColor={buildingType==="Carports" ? "#EA5F2C" : "#FFFFFF33"} color={buildingType==="Carports" ? "white" : "#FFFFFF33"}>
          <input type="radio" name="buildingType" value="Carports"
          onChange={this.props.handleChange}/>Carports
        </Label>
        <Label bg={buildingType==="Garages" ? "#EA5F2C" : "none"} borderColor={buildingType==="Garages" ? "#EA5F2C" : "#FFFFFF33"} color={buildingType==="Garages" ? "white" : "#FFFFFF33"}>
          <input type="radio" name="buildingType" value="Garages" 
          onChange={this.props.handleChange}/>Garages
        </Label>
        <Label bg={buildingType==="RV Covers" ? "#EA5F2C" : "none"} borderColor={buildingType==="RV Covers" ? "#EA5F2C" : "#FFFFFF33"} color={buildingType==="RV Covers" ? "white" : "#FFFFFF33"}>
          <input type="radio" name="buildingType" value="RV Covers" 
          onChange={this.props.handleChange}/>RV Covers
        </Label>
        <Label bg={buildingType==="Barns" ? "#EA5F2C" : "none"} borderColor={buildingType==="Barns" ? "#EA5F2C" : "#FFFFFF33"} color={buildingType==="Barns" ? "white" : "#FFFFFF33"}>
          <input type="radio" name="buildingType" value="Barns"
          onChange={this.props.handleChange}/>Barns
        </Label>
        <Label bg={buildingType==="Utility Buildings" ? "#EA5F2C" : "none"} borderColor={buildingType==="Utility Buildings" ? "#EA5F2C" : "#FFFFFF33"} color={buildingType==="Utility Buildings" ? "white" : "#FFFFFF33"}>
          <input type="radio" name="buildingType" value="Utility Buildings"
          onChange={this.props.handleChange}/>Utility Buildings
        </Label>
        <Label bg={buildingType==="Commercial Buildings" ? "#EA5F2C" : "none"} borderColor={buildingType==="Commercial Buildings" ? "#EA5F2C" : "#FFFFFF33"} color={buildingType==="Commercial Buildings" ? "white" : "#FFFFFF33"}>
          <input type="radio" name="buildingType" value="Commercial Buildings"
          onChange={this.props.handleChange}/>Commercial Buildings
        </Label>
      </Types>
      <FirstStepBar fill="white" />
    </div>
    )
  }
}

export default Step1

