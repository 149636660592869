import React from 'react'
import styled from "styled-components"
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from "gatsby"
import { Section, SectionTitle } from "../../components/Section"
import Map from "../../components/Map"

const Description = styled.div`
	text-align:center;
	& p {
		color: #fff;
	}
`

const MapBottomImg = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "map-bottom.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}
const MapSection = (data) =>{
	return(
		<Section pb="0px" mplr="0" mbp="0" bg="#1E4156">
			<SectionTitle color='#fff'>
				{data.data.title}
			</SectionTitle>
			{/* <Description dangerouslySetInnerHTML={{__html: data.data.description.childMarkdownRemark.html}} /> */}
			<Description><p>Nationwide services in almost all 50 states</p></Description>
			<div className="container">
				<Map />
			</div>
			<MapBottomImg />
		</Section>
	)
}

export default MapSection