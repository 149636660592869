import React from "react"
import { Section, SectionTitle } from "../../components/Section"
import IntroAccordian from "../../components/IntroAccordian"

const IntroSection = ({data, imageData}) => {
	return(
		<Section pt="50px" pb="0px" mbp="0px" mplr="0">
			<SectionTitle>{data.title}</SectionTitle>
			<p>{data.description.description}</p>
			<IntroAccordian data={data.features} imageData={imageData} />
		</Section>
	)
}

export default IntroSection