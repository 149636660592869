import React from "react"
import styled from "styled-components"
import axios from "axios"
import { navigate } from "gatsby"

import Step1 from "./first-step"
import Step2 from "./second-step"
import Step3 from "./third-step"

import { OR } from "../Section"
import { CustomBuilding, ButtonWrapper, Button } from "../FormStyle"

const CustomOR = styled(OR)`
  top: 80px;
  @media (max-width: 1200px) {
    top: inherit;
    bottom: -40px;
    left: calc(50% - 40px);
  }
  @media (max-width: 480px) {
    padding-top: 13px;
    font-size: 24px;
    line-height: 29px;
    height: 60px;
    width: 60px;
    bottom: -30px;
    left: calc(50% - 30px);
  }
`
const StepWrap = styled.div`
  min-height: 394px;
`

const Buttons = styled.div`
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonWrap = styled(ButtonWrapper)`
  position: relative;
  width: auto;
`;

class CustomBuildingForm extends React.Component {
  constructor(props) {
    super(props)
    // Set the initial input values
    this.state = {
      currentStep: 1, // Default is Step 1
      buildingType: "Carports",
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      zipcode: "",
      state: "",
      stateRequire: false,
      stateInputPattern: "",
      fNameRequire: false,
      fNameInputPattern: "",
      lNameRequire: false,
      lNameInputPattern: "",
      emailRequire: false,
      emailInputPattern: "",
      phoneRequire: false,
      phoneInputPattern: "",
      zipcodeRequire: false,
      zipCodeInputPattern: "",
      width: "",
      height: "",
      length: "",
      back_end: "",
      front_end: "",
      left_side: "",
      right_side: "",
    }
    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this._next = this._next.bind(this)
    this._prev = this._prev.bind(this)
  }

  // Use the submitted data to set the state
  handleChange(event) {
    const { name, value, pattern } = event.target
    this.setState({
      [name]: value,
    })

    const inputRegex = new RegExp(pattern);

    if (name === "state") {
      this.setState({
        stateRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          stateInputPattern: ""
        })
      } else {
        this.setState({
          stateInputPattern: "Should accept only 5 numbers"
        })
      }
    }
    if (name === "first_name") {
      this.setState({
        fNameRequire: false,
      })

      if (inputRegex.test(value)) {
        this.setState({
          fNameInputPattern: ""
        })
      } else {
        this.setState({
          fNameInputPattern: "Name should be in a-z A-Z"
        })
      }
    }
    if (name === "last_name") {
      this.setState({
        lNameRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          lNameInputPattern: ""
        })
      } else {
        this.setState({
          lNameInputPattern: "Name should be in a-z A-Z"
        })
      }
    }
    if (name === "email") {
      this.setState({
        emailRequire: false,
      })
      if (inputRegex.test(value)) {
        console.log(true)
        this.setState({
          emailInputPattern: ""
        })
      } else {
        console.log(false)
        this.setState({
          emailInputPattern: "Invalid email format"
        })
      }
    }
    if (name === "phone_no") {
      this.setState({
        phoneRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          phoneInputPattern: ""
        })
      } else {
        this.setState({
          phoneInputPattern: "Should accept only 10 digit and not greater than that."
        })
      }
    }
    if (name === "zipcode") {
      this.setState({
        zipcodeRequire: false,
      })
      if (inputRegex.test(value)) {
        this.setState({
          zipCodeInputPattern: ""
        })
      } else {
        this.setState({
          zipCodeInputPattern: "Should accept only 5 numbers"
        })
      }
    }
  }

  // Trigger an alert on form submission
  handleSubmit = event => {
    let currentStep = this.state.currentStep
    const { location } = this.props
    event.preventDefault()
    if (this.state.first_name === "") {
      this.setState({
        fNameRequire: true,
      })
    } else if (this.state.last_name === "") {
      this.setState({
        lNameRequire: true,
      })
    } else if (this.state.email === "") {
      this.setState({
        emailRequire: true,
      })
    } else if (this.state.phone_no === "") {
      this.setState({
        phoneRequire: true,
      })
    } else if (this.state.zipcode === "") {
      this.setState({
        zipcodeRequire: true,
      })
    } else if (this.state.fNameInputPattern === "" && this.state.lNameInputPattern === "" && this.state.stateInputPattern === "" && this.state.phoneInputPattern === "" && this.state.zipCodeInputPattern === "" && this.state.emailInputPattern === "") {
      const comment =
        "Installation State: " +
        this.state.state +
        "; Building Type: " +
        this.state.buildingType +
        "; Width: " +
        this.state.width +
        "; Length: " +
        this.state.length +
        "; Height: " +
        this.state.height +
        "; Back End: " +
        this.state.back_end +
        "; Front End: " +
        this.state.front_end +
        "; Left Side: " +
        this.state.left_side +
        "; Right Side: " +
        this.state.right_side
      const data = {
        source: location.origin,
        form: 'Custom Building Quote Form',
        page: location.pathname,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone_no: this.state.phone_no,
        zipcode: this.state.zipcode,
        comment: comment,
      }
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: {
          Authorization:
            "Bearer " +
            "13IMU4ZLF9WyrjRj6JuTfVRwGswQVmfBpO39w7bYvaFT93nbcTKE8PnUBv4bWMrM",
        },
      })
        .then(res => {
          this.setState({
            first_name: "",
            last_name: "",
            email: "",
            phone_no: "",
            zipcode: "",
            state: "",
            currentStep: currentStep,
          })
          navigate("/thank-you-customize-building/")
        })
        .catch(error => {
          alert(error)
        })
    } else {
      return;
    }
  }

  _next() {
    let currentStep = this.state.currentStep
    // If the current step is 1 or 2, then add one on "next" button click
    if (currentStep === 1) {
      if (this.state.state === "" || this.state.stateInputPattern !== "") {
        this.setState({
          stateRequire: true,
        })
      } else {
        currentStep = 2
      }
    } else {
      currentStep = currentStep >= 3 ? 4 : currentStep + 1
    }
    this.setState({
      currentStep: currentStep,
    })
  }

  _prev() {
    let currentStep = this.state.currentStep
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1
    this.setState({
      currentStep: currentStep,
    })
  }
  get previousButton() {
    let currentStep = this.state.currentStep
    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <Button
          onClick={this._prev}
          width="200px"
          className="btn btn-secondary"
          type="button"
        >
          <div>Previous</div>
        </Button>
      )
    } 
    // ...else return nothing
    return null
  }

  get nextButton() {
    let currentStep = this.state.currentStep
    // If the current step is not 3, then render the "next" button
    if(currentStep === 1) {
      return (
        <Button width="200px" mWidth="180px" type="button" onClick={this._next}>
          <div>Get Started</div>
        </Button>
      )
    } else if (currentStep === 2) {
      return (
        <Button width="200px" type="button" onClick={this._next}>
          <div>Next</div>
        </Button>
      )
    } else if (currentStep === 3) {
      return (
        <Button
          width="200px"
          type="button"
          form="homepage-form"
          onClick={this.handleSubmit}
        >
          <div>Submit</div>
        </Button>
      )
    }

    return null
  }
  render() {
    return (
      <CustomBuilding>
        <h2>Get Your Custom Building Quote in Just a few Clicks</h2>
        <form id="homepage-form">
          <StepWrap>
            <Step1
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              buildingType={this.state.buildingType}
              state={this.state.state}
              stateInputPattern={this.state.stateInputPattern}
              stateRequire={this.state.stateRequire}
            />
            <Step2
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              width={this.state.width}
              length={this.state.length}
              height={this.state.height}
              back_end={this.state.back_end}
              front_end={this.state.front_end}
              left_side={this.state.left_side}
              right_side={this.state.right_side}
            />
            <Step3
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              first_name={this.state.first_name}
              fNameInputPattern={this.state.fNameInputPattern}
              last_name={this.state.last_name}
              lNameInputPattern={this.state.lNameInputPattern}
              email={this.state.email}
              emailInputPattern={this.state.emailInputPattern}
              phone_no={this.state.phone_no}
              phoneInputPattern={this.state.phoneInputPattern}
              zipcode={this.state.zipcode}
              zipCodeInputPattern={this.state.zipCodeInputPattern}
              fNameRequire={this.state.fNameRequire}
              lNameRequire={this.state.lNameRequire}
              emailRequire={this.state.emailRequire}
              phoneRequire={this.state.phoneRequire}
              zipcodeRequire={this.state.zipcodeRequire}
            />
          </StepWrap>
          <Buttons>
            <ButtonWrap>{this.previousButton}</ButtonWrap>
            <ButtonWrap>{this.nextButton}</ButtonWrap>
          </Buttons>
        </form>
        <CustomOR>OR</CustomOR>
      </CustomBuilding>
    )
  }
}

export default CustomBuildingForm
