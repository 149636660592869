import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import RedSectionButton from "../../components/Button/RedSectionButton"
import {
  Section,
  SectionTitle,
  SectionButtonWrapper,
  UspsDot,
  MobileUspsDot,
} from "../../components/Section"

import StepPoint from "../../components/Icons/StepPoint"
import DotLine1 from "../../components/Icons/DotLine1"
import DotLine2 from "../../components/Icons/DotLine2"
import TransParencyIcon from "../../components/Icons/TransParencyIcon"
import HandPointer from "../../components/Icons/HandPointer"
import ClockIcon from "../../components/Icons/ClockIcon"
import VerticalDotLine from "../../components/Icons/VerticalDotLine"
import PlayButtonIcon from "../../components/Icons/PlayButtonIcon"

import ItemHover from "../../components/ItemHover"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  @media (max-width: 1300px) {
    grid-template-columns: repeat(1, auto);
    grid-template-rows: auto;
  }
`

const UspsGrid = styled.div`
  margin: 70px 0;
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  & svg {
    vertical-align: middle;
  }
  & h3 {
    font-size: 24px;
    line-height: 29px;
    color: #062c44;
    margin-bottom: 5px;
  }
  & p {
    text-align: left;
  }
`

const Left = styled.div`
  position: relative;
  margin-right: -350px;
  @media (max-width: 1300px) {
    text-align: center;
    margin-right: 0px;
  }
`

const TextWrap = styled.div`
  position: relative;
  z-index: 1;
  background: white;
  padding: 20px 0;
  @media (max-width: 1300px) {
    & h2 {
      margin: auto;
      text-align: center;
      margin-bottom: 30px;
    }
    & div {
      margin: auto;
      text-align: center !important;
    }
  }
  @media (max-width: 768px) {
    & h2 {
      margin-top: 40px;
    }
    width: 100%;
  }
`
const Text = styled.div`
  width: 250px;
`

const Center = styled.div`
  & .gatsby-image-wrapper {
    width: 430px;
    margin: auto;
  }
  @media (max-width: 1300px) {
    text-align: center;
    margin-top: -265px;
  }
  @media (max-width: 440px) {
    & .gatsby-image-wrapper {
      width: 320px;
      margin: auto;
    }
  }
`
const DesktopUsps = styled.div`
  display: block;
  @media (max-width: 1300px) {
    display: none;
  }
`
const MobileUsps = styled.div`
  display: none;
  @media (max-width: 1300px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 500px;
    width: 100%;
    margin: auto;
    text-align: center;
  }
  @media (max-width: 500px) {
    margin-top: 20px;
  }
  @media (max-width: 400px) {
    margin-top: 30px;
  }
`
const MobileClock = styled.div`
  margin-top: -20px;
  z-index: 5;
  @media (max-width: 500px) {
    margin-top: -40px;
  }
  @media (max-width: 400px) {
    margin-top: -50px;
  }
`
const Description = styled.div`
  & p {
    font-size: 16px;
    line-height: 26px;
    color: #010920;
    text-align: left;
  }
`
const Sections = styled(Section)`
  padding:0 0 30px 0;
  @media (min-width: 992px) {
    padding:0 0 40px 0;
  }
  @media (min-width: 1200px) {
    padding:0 0 60px 0;
  }
  @media (min-width: 1600px) {
    padding:0 0 90px 0;
  }
`

const CirclBgImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "CircleBgImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.placeholderImage.childImageSharp.fluid}
      alt="Circle Background"
    />
  )
}
const SmallDepositSection = data => {
  return (
    <Sections>
      <div className="container">
        <Wrapper>
          <Left>
            <TextWrap>
              <SectionTitle textAlign="left" ml="0">
                {data.data.title}
              </SectionTitle>
              <Description
                dangerouslySetInnerHTML={{
                  __html: data.data.description.childMarkdownRemark.html,
                }}
              />
            </TextWrap>
          </Left>
          <Center>
            <CirclBgImage />
          </Center>
          <DesktopUsps>
            <UspsGrid>
              <UspsDot ml="-90px" sml="-80px">
                <StepPoint />
                <DotLine1 />
              </UspsDot>
              <div>
                <ClockIcon />
              </div>
              <Text>
                <h3>Quick</h3>
                <p>Just apply by filling a simple form</p>
              </Text>
            </UspsGrid>
            <UspsGrid>
              <UspsDot ml="-30px" sml="-20px">
                <StepPoint />
                <DotLine2 />
              </UspsDot>
              <div>
                <HandPointer />
              </div>
              <Text>
                <h3>Easy</h3>
                <p>Simply answer a few questions and sign the contract</p>
              </Text>
            </UspsGrid>
            <UspsGrid>
              <UspsDot ml="-85px" sml="-75px">
                <StepPoint />
                <DotLine1 />
              </UspsDot>
              <div>
                <TransParencyIcon />
              </div>
              <Text>
                <h3>Transparent</h3>
                <p>
                  If approved, choose the available offer that suits you best.
                </p>
              </Text>
            </UspsGrid>
          </DesktopUsps>
          <MobileUsps>
            <MobileUspsDot mt="-100px">
              <StepPoint />
              <div>
                <VerticalDotLine />
              </div>
              <div style={{ marginTop: "10px" }}>
                <HandPointer />
              </div>
              <div>
                <h3>Easy</h3>
                <p>Simply answer a few questions and sign the contract</p>
              </div>
            </MobileUspsDot>
            <MobileClock>
              <StepPoint />
              <div>
                <VerticalDotLine />
              </div>
              <div style={{ marginTop: "-60px", marginLeft: "-13px" }}>
                <ClockIcon />
              </div>
              <div>
                <h3>Quick</h3>
                <p>Just apply by filling a simple form</p>
              </div>
            </MobileClock>
            <MobileUspsDot mt="-100px">
              <StepPoint />
              <div>
                <VerticalDotLine />
              </div>
              <div style={{ marginTop: "10px" }}>
                <TransParencyIcon />
              </div>
              <div>
                <h3>Transparent</h3>
                <p>
                  If approved, choose the available offer that suits you best.
                </p>
              </div>
            </MobileUspsDot>
          </MobileUsps>
        </Wrapper>
      </div>
      <SectionButtonWrapper>
        <Link to="/buying-guide/financing-process/">
          <RedSectionButton>
            <ItemHover>
              {hoverItem => (
                <div>
                  <PlayButtonIcon fill={hoverItem ? "white" : "#ED602B"} />{" "}
                  Watch Us in Action
                </div>
              )}
            </ItemHover>
          </RedSectionButton>
        </Link>
      </SectionButtonWrapper>
    </Sections>
  )
}

export default SmallDepositSection
