import React, { Component } from "react"
import { Link } from "gatsby"
import { Section, SectionTitle } from "../../components/Section"
import SectionButton from "../../components/Button/SectionButton"
import ProductCardList from "../../components/ProductCardList"

class CardSection extends Component {
  render() {
    const { data, productData, location } = this.props
    let products = []
    productData.edges.forEach(function(node, index) {
      if (index < 8) {
        products.push(node)
      }
    })
    return (
      <Section bg="#F4FBFF">
        <SectionTitle maxWidth="1000px">{data.title}</SectionTitle>
        <p>{data.description.description}</p>
        <div className="container">
          <ProductCardList data={products} layout="home" location={location} />
        </div>
        <div style={{ textAlign: "center", marginTop: "65px" }}>
          <Link to="/products/">
            <SectionButton text="Explore More Buildings" />
          </Link>
        </div>
      </Section>
    )
  }
}

export default CardSection
