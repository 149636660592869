import React from 'react'
import WidthIcon from "../Icons/WidthIcon"
import LengthIcon from "../Icons/LengthIcon"
import HeightIcon from "../Icons/HeightIcon"
import SecondStepBar from "../VerticalStepBar/SecondStepBar"
import { Dimensions, DimensionItem, Value, SideGrid} from "../FormStyle"

class Step2 extends React.Component {
  render() {
    if (this.props.currentStep !== 2) { // Prop: The current step
      return null
    }
    // The markup for the Step 1 UI
    return(
      <div className="form-group">
        <h5>Choose the Dimensions</h5>
        <Dimensions>
          <DimensionItem>
            <div>
              <WidthIcon /> 
            </div>
            <Value>
              <p>Width</p>
            </Value>
            <input type="number" name="width" onChange={this.props.handleChange} value={this.props.width} />
          </DimensionItem>
          <DimensionItem>
            <div>
              <LengthIcon />
            </div>
            <Value>
              <p>Length</p>
            </Value>
            <input type="number" name="length" onChange={this.props.handleChange} value={this.props.length} />
          </DimensionItem>
          <DimensionItem> 
            <div>
              <HeightIcon />
            </div>
            <Value>
              <p>Height</p>
            </Value>
            <input type="number" name="height" onChange={this.props.handleChange} value={this.props.height}  />
          </DimensionItem>
        </Dimensions>
        <SideGrid>
          <div>
            <h5>Back Ends</h5>
            <label><input type="radio" name="back_end" value="Open" onChange={this.props.handleChange}/>Open </label><br/>
            <label><input type="radio" name="back_end" value="Fully Enclose" onChange={this.props.handleChange}/>Fully Enclose</label><br/>
            <label><input type="radio" name="back_end" value="Gable Ends" onChange={this.props.handleChange}/>Gable Ends</label><br/>
          </div>
          <div>
            <h5>Front Ends</h5>
            <label><input type="radio" name="front_end" value="Open" onChange={this.props.handleChange}/>Open </label><br/>
            <label><input type="radio" name="front_end" value="Fully Enclose" onChange={this.props.handleChange}/>Fully Enclose</label><br/>
            <label><input type="radio" name="front_end" value="Gable Ends" onChange={this.props.handleChange}/>Gable Ends</label><br/>
          </div>
          <div>
            <h5>Left Side</h5>
            <label><input type="radio" name="left_side" value="Open" onChange={this.props.handleChange}/>Open </label><br/>
            <label><input type="radio" name="left_side" value="Fully Enclose" onChange={this.props.handleChange}/>Fully Enclose</label><br/>
            <label><input type="radio" name="left_side" value="Gable Ends" onChange={this.props.handleChange}/>Gable Ends</label><br/>
          </div>
          <div>
            <h5>Right Side</h5>
            <label><input type="radio" name="right_side" value="Open" onChange={this.props.handleChange}/>Open </label><br/>
            <label><input type="radio" name="right_side" value="Fully Enclose" onChange={this.props.handleChange}/>Fully Enclose</label><br/>
            <label><input type="radio" name="right_side" value="Gable Ends" onChange={this.props.handleChange}/>Gable Ends</label><br/>
          </div>
        </SideGrid>
        <SecondStepBar fill="white" />
      </div>
    )
  }
}

export default Step2