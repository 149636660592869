import React from 'react'
import ThirdStepBar from "../VerticalStepBar/ThirdStepBar"
import { Description, InputGrid, CustomValidationMessage, RequiredTriangle } from "../FormStyle"


class Step3 extends React.Component {
  render() {
    const { first_name, fNameRequire, fNameInputPattern, last_name, lNameRequire, lNameInputPattern, email, emailRequire, emailInputPattern, phone_no, phoneRequire, phoneInputPattern, zipcode, zipcodeRequire, zipCodeInputPattern } = this.props
    
    if (this.props.currentStep !== 3) { // Prop: The current step
      return null
    }
    // The markup for the Step 1 UI
    return(
      <div className="form-group">
        <Description>Tell us your more information so our customer care executives can call you and describe our services in a better way.</Description>
        <InputGrid>
          <div>
            <input type="text" name="first_name" placeholder="Your First Name" onChange={this.props.handleChange} value={first_name} pattern="[A-Za-z\s]+$" />
            <CustomValidationMessage display={fNameInputPattern ?"block": "none"}><RequiredTriangle /><span>{fNameInputPattern}</span></CustomValidationMessage>
            <CustomValidationMessage display={fNameRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
          </div>
          <div>
            <input type="text" name="last_name" placeholder="Your Last Name" onChange={this.props.handleChange} value={last_name} pattern="[A-Za-z\s]+$" />
            <CustomValidationMessage display={lNameInputPattern ?"block": "none"}><RequiredTriangle /><span>{lNameInputPattern}</span></CustomValidationMessage>
            <CustomValidationMessage display={lNameRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
          </div>
          <div>
            <input type="email" name="email" placeholder="Your Email" onChange={this.props.handleChange} value={email} pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" />
            <CustomValidationMessage display={emailInputPattern ?"block": "none"}><RequiredTriangle /><span>{emailInputPattern}</span></CustomValidationMessage>
            <CustomValidationMessage display={emailRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
          </div>
          <div>
            <input name="phone_no" placeholder="Phone Number" onChange={this.props.handleChange} value={phone_no} pattern="^[0-9]{10}$" />
            <CustomValidationMessage display={phoneInputPattern ?"block": "none"}><RequiredTriangle /><span>{phoneInputPattern}</span></CustomValidationMessage>
            <CustomValidationMessage display={phoneRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
          </div>
          <div>
            <input name="zipcode" placeholder="Zip Code" onChange={this.props.handleChange} value={zipcode} pattern="^[0-9]{5}$"  />
            <CustomValidationMessage display={zipCodeInputPattern ?"block": "none"}><RequiredTriangle /><span>{zipCodeInputPattern}</span></CustomValidationMessage>
            <CustomValidationMessage display={zipcodeRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomValidationMessage>
          </div>
        </InputGrid>        
        <ThirdStepBar fill="white" />
      </div>
    )
  }
}

export default Step3
